import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

//Context
import { useRelyingParty } from '../../context/RelyingPartyContext';

// Components
import PendingAuthentication from '../member-auth/PendingAuthentication';

// Utils
import { ctxValue } from '../../utils/config';
import { formatMobileNumber } from '../../utils/helpers';
import { getVerificationResult, getRelyingParty } from '../../utils/services';

//Styles
import styles from './VerificationResult.module.css';

const subdomain = ctxValue('SUBDOMAIN');

const VerificationResult = () => {
  const history = useHistory();
  const { relyingParty, setRelyingParty } = useRelyingParty();
  const { verificationRequestId } = useParams();
  const [ vrStatus, setVrStatus ] = useState('loading');
  const [ mobileNumber, setMobileNumber ] = useState();
  const [ authContext, setAuthContext ] = useState();
  const [ agentClaimData, setAgentClaimData ] = useState();
  const [ ipInfo, setIpInfo ] = useState();
  const [ authenticatedBy, setAuthenticatedBy ] = useState();

  useEffect(() => {
    _getVerificationResult(verificationRequestId);
  }, [verificationRequestId]);

  useState(() => {
    async function _getRelyingPartyInfo() {
      const rpResult = await getRelyingParty();
      setRelyingParty(rpResult?.data);
    };
    _getRelyingPartyInfo();
  }, [relyingParty]);

  const _getVerificationResult = async (id) => {
    const vrResult = await getVerificationResult(id);

    if (vrResult?.status === 404) {
      setVrStatus('not-found');
      return;
    }
    if (vrResult?.status !== 200) {
      setVrStatus('error');
      return;
    }

    setVrStatus(vrResult.data.result);
    setMobileNumber(vrResult.data.mobileNumber);
    setAuthContext(vrResult.data.authContext);

    if (vrResult.data.result === 'pass') {
      setAgentClaimData(vrResult.data.agentClaimData);
      setIpInfo(vrResult.data?.ipInfo);

      const authFactors = vrResult.data?.authFactors;
      if ( Array.isArray(authFactors) && authFactors.length>0 ) {
        const lastAuthFactorKey = authFactors.slice(-1)[0]; // pull the last auth factor in the array
        const authenticatedBy = 
          { 'ac'      : 'Registered Device'
          , 'psml'    : 'Preenroll Magic Link'          // only allowed on the first authentication request
          , 'pvc'     : 'Previous Verification Cookie'
          , 'smsotp'  : 'SMS OTP'
          , 'passkey' : 'Passkey'
          }[lastAuthFactorKey] || undefined;
        setAuthenticatedBy(authenticatedBy);
        if (!authenticatedBy) console.warn(`useEffect() setAuthenticatedBy:undefined - unknown authFactorKey:${lastAuthFactorKey}`);
      }
    }
  };

  const onAuthResult = (id) => {
    _getVerificationResult(id);
  };

  const okButtonClick = (lookupMobileNumberOnReturn) => {
    const search = (lookupMobileNumberOnReturn) ? `?mobileNumber=${mobileNumber}` : undefined;
    history.push({ pathname: '/lookup', hash: subdomain, search });
  };

  // Post a messqage callback if this enterprise has a value defined for 'agentPopupOrigin' in the relying_party.config table
  const postMessageCallback = (data) => {
    if (window?.opener) { // for poped up windows
      if (relyingParty?.agentPopupOrigin) {
        window.opener.postMessage({...data, source:'IDgoAgent'}, relyingParty.agentPopupOrigin);
        return;
      }
      console.warn(`VerificationResult: relyingParty.agentPopupOrigin not set - unable to postMessage to opener`);
    }
    if (window?.parent) { // for iframed up windows
      console.warn(`VerificationResult: posting data: ${JSON.stringify(data,null,3)}`);
      window.parent.postMessage({...data, source:'IDgoAgent'}, '*');
      return;
    }
  };

  const renderAuthResult = () => {
    if (vrStatus === 'loading')   return <></>;
    if (vrStatus === 'pass')      return renderAuthResult_pass();
    if (vrStatus === null)        return renderAuthResult_pending();
    if (vrStatus === 'rejected')  return renderAuthResult_failed('Declined', 'User declined this Authenticate request');
    if (vrStatus === 'fail')      return renderAuthResult_failed('System Error', 'Authenticate request failed');
    if (vrStatus === 'not-found') return renderAuthResult_failed('Not Found', 'Authenticate request not found');
    return renderAuthResult_failed('System Error', `Authenticate request exception. Unknown status: ${vrStatus}`);
  };

  const renderAuthResult_failed = (title, message) => {
    postMessageCallback({result:vrStatus});
    return <>
      <div className={styles.failedTitle}>{title}</div>
      <div className={styles.failedMessage}>{message}</div>
    </>
  };

  const renderAuthResult_pending = () => {
    return <>
      <div className={styles.failedTitle}>Pending</div>
      <div className={styles.mobileNumber}>{formatMobileNumber(mobileNumber)}</div>
      <PendingAuthentication
        mobileNumber={mobileNumber}
        authContext={authContext}
        cancelWaitingForAuth={()=>okButtonClick(true)}
        handleAuthResult={onAuthResult}
      />
    </>
  };

  const renderAuthResult_pass = () => {
    postMessageCallback({result: 'pass', data: agentClaimData});
    return <>
      <div className={styles.gridContainer}>
        <div className={styles.authImageContainer}>
          <img src={`${process.env.PUBLIC_URL}/icons/ic_check_mark_grn_lg.png`} alt='Authenticated' className={styles.authImage}></img>
        </div>
        <div className={styles.authHeader}>
          Authenticated
        </div>
        { authenticatedBy &&
          <div className={styles.authBy}>
            <span className={styles.authLabel}>by</span><b>{authenticatedBy}</b>
          </div>
        }
        <div className={styles.authLocation}><span className={styles.authLabel}>location</span>
          { (ipInfo?.isRelay==='false') ? <>{ipInfo.city}, {ipInfo.regionName}</> : <>not available</> }
        </div>
      </div>
      { renderAgentClaimData(agentClaimData) }
    </>
  };

  const renderField = (field, index) => {
    return <div key={index}>
        <div className={styles.fieldLabel}>{ field.label }</div>
        <div className={styles.fieldValue}>{ field.value }</div>
      </div>
  };

  const renderAgentClaimData = (acd) => {
    if (!acd) return <></>;
    return <> { acd.map((field, index) => renderField(field, index)) } </>
  };

  const renderReturnOptions = () => {
    if (vrStatus === 'loading' || vrStatus === null)   return <></>;
    return <>
      <div className='text-center' style={{ marginBottom: '40px', marginTop: '20px' }}>
        <button className='btn btn-primary btn-lg shadow-none' onClick={() => okButtonClick(false)} >
          OK
        </button>
        <div className={styles.returnToLookup} onClick={() => okButtonClick(true)}>
          Return to lookup with mobile number
        </div>
      </div>
    </>;
  };

  return (
    <>
      <div className='row'>
        <div className='mx-auto' style={{ width: '280px' }}>
          { renderAuthResult() }
          { renderReturnOptions() }
        </div>
      </div>
    </>
  );
};

export default VerificationResult;