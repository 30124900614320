import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';

//Context
import { useRelyingParty } from '../../context/RelyingPartyContext';

// Components
import AccountToolsPanel from '../member-auth/AccountToolsPanel';
import PendingAuthentication from '../member-auth/PendingAuthentication';

//Utils
import moment from 'moment';
import PubNubReact from 'pubnub';
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, FormFeedback } from 'reactstrap';
import { useAlert } from 'react-alert';
import { jwtDecode } from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';
import { ctxValue } from '../../utils/config';
import { getAccessToken, getWindowSize } from '../../utils/session';
import { validateParamsWithRules } from '../../utils/helpers';
import 
{ getAccountStatus as getAccountStatusSvc
, authenticateUser as authenticateUserSvc
, revokeEnrollment as revokeEnrollmentSvc
, deleteAccount as deleteAccountSvc
, enrollUser as enrollUserSvc
} from '../../utils/services';

// Material components
import { IconButton, Divider, Menu, MenuItem } from '@mui/material';
// Material icons (https://mui.com/material-ui/material-icons/
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

//Styles
import styles from './AccountStatus.module.css';

const dateFormat = 'MMM Do YY, h:mma';

const pubnub = new PubNubReact({
  subscribe_key: ctxValue('PUBNUB_SUBSCRIBE_KEY'),
  ssl: true,
  uuid: 'call-center-app',
});

const g_env = ctxValue('ENV');

const AccountStatus = (props) => {
  const history = useHistory();
  const alert = useAlert();
  const {relyingParty} = useRelyingParty();

  const [mobileNumber, setMobileNumber] = useState(props.phone);
  const [accountStatus, setAccountStatus] = useState({status:0}); // used to render account info
  const [coziId, setCoziId] = useState(undefined);  // used to manage the pubnub connection

  const [enrollCollectDataModal, setEnrollCollectDataModal] = useState(false);
  const [enrollCollectDataModalFeedback, setEnrollCollectDataModalFeedback] = useState({});
  const [enrollCollectedData, setEnrollCollectedData] = useState(undefined);

  const [revokeModal, setRevokeModal] = useState(false);
  const [revokeModalFeedback, setRevokeModalFeedback] = useState(false);
  const [revokeReason, setRevokeReason] = useState('');

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalFeedback, setDeleteModalFeedback] = useState(false);
  const [deleteVerification, setDeleteVerification] = useState('');
  const [decodedToken, setDecodedToken] = useState();

  const [menuAnchor, setMenuAnchor] = useState(null);
  const menuOpen = Boolean(menuAnchor);

  const [callerIdNomatchChecked, setCallerIdNomatchChecked] = useState(false);
  const [authContext, setAuthContext] = useState(undefined);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    async function __getAccessToken() {
      const {token} = await getAccessToken();
      if (!token) return;
      const decoded = jwtDecode(token);
      setDecodedToken(decoded)
    };
    __getAccessToken();
  }, []);

  useEffect(() => {
    if (mobileNumber !== props.phone) {
      setAccountStatus({status:0});
      setMobileNumber(props.phone);
    }
    async function __getAccountStatus () {
      const result = await getAccountStatusSvc(mobileNumber);
      if (result?.status===200) {
        // if this account has already been enrolled, removed the defined set of data to collect
        if (result?.data?.proofs?.Invitation?.completed && result?.data?.enrollCollectData) {
          delete result.data.enrollCollectData;
        }
        setAccountStatus({...result.data, status:result.status});
        setCoziId(result.data.coziId);
        return;
      }
      if (result?.status===404) {
        // account not found plus defined data to collect on enroll
        setAccountStatus(
          { status:404
          , enrollCollectData : result?.data?.enrollCollectData
          , enrollCollectRules: result?.data?.enrollCollectRules
          }); 
        return;
      }
      setAccountStatus({status:500});
    }

    if (accountStatus?.status===0) {
      __getAccountStatus();  
    }
  }, [mobileNumber, accountStatus, props.phone]);

  useEffect(() => {
    const channel = `clientactivity.${coziId}`;
    async function __listenForPubnubMessages () {
      const listener = {
        message: (msg) => {
          if (msg?.message?.msg?.type==='event') {
            //console.debug(`AccountStatus.useEffect() mobileWebEvent: ${JSON.stringify(msg.message.msg?.data,null,3)}`);
            handleMobileWebEvent(msg.message.msg.data);
          }
        },
      };
      pubnub.addListener(listener);
      console.debug(`AccountStatus.useEffect() subscribing to channel: ${channel}`);
      pubnub.subscribe({ channels: [channel] });
      return;
    };

    if (!coziId) return;
    __listenForPubnubMessages();

    return () => {
      if (!coziId) return;
      console.debug(`AccountStatus.useEffect() unsubscribing from channel: ${channel}`);
      pubnub.unsubscribe({ channels: [channel] });
      pubnub.removeAllListeners(); // required even though it shows as "may not exist" (or you will get duplicates)
    };
    // eslint-disable-next-line
  }, [coziId]);

  // We have seen instances of onAuthenticationHandler being called twice on one event. Could not recreate, so applied this debounce pattern.
  // Seeing this same issue on onEnrollHandler being called 3 times on one event.
  function debounceSubsequentEvents(func, timeout = 300){
    let timer;
    return (...args) => {
      if (!timer) {
        func.apply(this, args);
      }
      clearTimeout(timer);
      timer = setTimeout(() => {
        timer = undefined;
      }, timeout);
    };
  };

  // overrode the original onAuthenticationHandler() with this wrapper function
  const onAuthenticationHandler = debounceSubsequentEvents(() => onAuthenticationHandlerDebounced());
  // overrode the original onEnrollHandler() with this wrapper function
  const onEnrollHandler = debounceSubsequentEvents(() => onEnrollHandlerDebounced());

  const menuClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };
  const menuClose = () => {
    setMenuAnchor(null);
  };

  const handleMobileWebEvent = (data) => {
    const eventMessages = {
      'device-check'                : {refresh:true , error:false, msg: 'Device check complete!'},
      'enrollment-complete'         : {refresh:false, error:false, msg: 'Enrollment complete!'},
      'verified-email-initiated'    : {refresh:false, error:false, msg: 'Email verification initiated'},
      'verified-email-success'      : {refresh:true , error:false, msg: 'Email verification success'},
      'verified-email-failed'       : {refresh:false, error:true , msg: 'Email verification failed'},
      'reset-account'               : {refresh:true , error:false, msg: 'IDgo account purged'},
      'verification-toggled'        : {refresh:true , error:false, msg: 'Verifications toggled'},
      'fido-available'              : {refresh:false, error:false, msg: 'Passkeys available'},
      'fido-not-available'          : {refresh:false, error:true , msg: 'Passkeys not available'},
      'fido-registeration-failure'  : {refresh:false, error:true , msg: 'Passkey setup failed'},
      'fido-registeration-success'  : {refresh:true , error:false, msg: 'Passkey setup success!'},
      'fido-registeration-cancelled': {refresh:false, error:true , msg: 'Passkey bypassed by user'},
      'dialed-number-verified-yes'  : {refresh:false, error:false, msg: 'User verified dialed number'},
      'dialed-number-verified-no'   : {refresh:false, error:true , msg: 'User dialed unknown number'},
    }
    // if subdomain provided but it does not match... skip it
    if (data?.subdomain && data.subdomain!==ctxValue('SUBDOMAIN')) return;
    if (!(data.name in eventMessages)) {
      console.info(`Mobile Web event ${data.name} skipped`);
      return;
    }
    if (data.name==='enrollment-complete') {
      const msg = 
        <div className={styles.enrollmentCompleteWrapper}>
          <img className={styles.enrollmentCompleteImg} src={`${process.env.PUBLIC_URL}/icons/ic_check_mark_grn_lg.png`} alt='Enrollment Complete' />
          <span className={styles.enrollmentCompleteHdr}>Enrollment Complete</span>
          <div className={styles.enrollmentCompleteMsg}>The user has completed IDgo enrollment!</div>
        </div>
      alert.show(msg);
      return;
    }
    if (data.name==='dialed-number-verified-no') {
      const msg = <div>User confirmed the number they dialed was not {relyingParty.callCenterPhoneNumber}</div>;
      alert.show(msg, { type: 'error', title: 'Suspicious Call', onClose: () => { onCancelButtonClick(); } });
      return;
    }
    if (eventMessages[data.name].refresh) setAccountStatus({status:0});
    if (eventMessages[data.name].error) {
      toast.error(eventMessages[data.name].msg, {position:'top-left', duration:5000});
    } else {
      toast.success(eventMessages[data.name].msg, {position:'top-left', duration:4000});
    }
  };

  const openProofsReivewWindow = () => {
    menuClose();
    const subdomain = ctxValue('SUBDOMAIN');
    const win = getWindowSize('proofsReviewWinSize') || {innerHeight:600,innerWidth:900,screenY:10,screenX:10};
    window.open
      ( `${process.env.PUBLIC_URL}/proofs-review?m=${mobileNumber}#${subdomain}`
      , 'proofs-review'
      , `scrollbars=yes,resizable=yes,top=${win.screenY},left=${win.screenX},width=${win.innerWidth},height=${win.innerHeight}`
      );
  };

  const onAuthenticationHandlerDebounced = async () => {
    menuClose();

    const ac = uuidv4();
    setDisabled(true);
    props.disableLookup(true);

    // AAD tokens will have a 'name' claim, IDgo tokens have 'agentFirstName' and 'agentLastName'
    const agentName = decodedToken?.name || `${decodedToken?.agentFirstName} ${decodedToken?.agentLastName}` || 'missing';

    const params =
    { coziId
    , authContext             : ac
    , mobileNumber
    , realm                   : relyingParty?.realm
    , callerIdNomatchChecked
    , agentName
    };

    const result = await authenticateUserSvc(params);
    if (result?.status!==201) {
      props.disableLookup(false);
      alert.show('Authentication attempt failed', {title: 'System Error'});
      return;
    }

    setAuthContext(ac);
  };

  const deleteCancelModal = () => {
    setDeleteModal(false);
    setDeleteModalFeedback(false);
  };

  const onDeleteVerificationChange = (e) => {
    setDeleteVerification(e.target.value);
  };

  const onPromptForDeleteAccountHandler = () => {
    menuClose();
    setDeleteModal(true);
  };

  const onEnrollHandlerDebounced = async () => {
    // If we need to collect enrollment data, show the modal dialog
    if (accountStatus?.enrollCollectData) {
      setEnrollCollectDataModal(true);
      return;
    }
    // Otherwise just enroll the user
    await enrollUser();
  };

  const enrollUser = async () => {
    menuClose();
    // AAD tokens will have a 'name' claim, IDgo tokens have 'agentFirstName' and 'agentLastName'
    const createdBy = decodedToken?.name || `${decodedToken?.agentFirstName} ${decodedToken?.agentLastName}` || 'missing';
    const result = await enrollUserSvc(mobileNumber, createdBy, enrollCollectedData);
    if (result?.status!==201) {
      alert.show(result?.response?.data?.message, {title: 'Error enrolling member'});      
      return
    }
    const accountCreated         = (result.data?.accountCreated        ===1) ? <li>Account created</li> : '';
    const claimInvitationCreated = (result.data?.claimInvitationCreated===1) ? <li>Enrollment created</li> : '';
    const msg = <div><ul>{accountCreated}{claimInvitationCreated}<li>SMS message sent</li></ul></div>; 
    setAccountStatus({status:0}); // trigger refresh
    alert.show(msg, {title: 'Member Enrolled'});
  };

  const onEnrollCollectedDataChange = (e, field) => {
    let currentData = enrollCollectedData || {};
    currentData[field] = e.target.value;
    setEnrollCollectedData(currentData);
  };

  const enrollCancelModal = () => {
    setEnrollCollectDataModal(false);
    setEnrollCollectDataModalFeedback({});
  };

  const enrollConfirmWithInfo = async () => {
    if (accountStatus?.enrollCollectData && Array.isArray(accountStatus.enrollCollectData) && accountStatus.enrollCollectData.length > 0) {
      const collectedData = enrollCollectedData || {};
      let feedbackItems = {};
      let feedbackFound = false;
      if (accountStatus?.enrollCollectRules) {
        // create a fieldName > Label map so we can create feedback messages
        const fieldToLabelMap = {};
        accountStatus.enrollCollectData.forEach((item) => {
          fieldToLabelMap[item.field] = item.label;
        });
        // declarative JOI rule validation
        const validationResult = validateParamsWithRules(collectedData, accountStatus.enrollCollectRules);
        if (validationResult.error) {
          feedbackFound = true;
          for (const d in validationResult.error.details) {
            const field = validationResult.error.details[d].context.key;
            feedbackItems[field] = `${fieldToLabelMap[field]}, ${validationResult.error.details[d].message}`;
          }
        }
      } else {
        // enrollCollectRules not provided so create simple "required values" feedback messages
        accountStatus.enrollCollectData.forEach((item) => {
          if (!(item.field in collectedData) || collectedData[item.field].trim() === '') {
            feedbackFound = true;
            feedbackItems[item.field] = `Please provide the account holder's ${item.label}`;
          }
        });
      }
      if (feedbackFound) {
        setEnrollCollectDataModalFeedback(feedbackItems);
        return;
      }
      setEnrollCollectDataModal(false);
      setEnrollCollectDataModalFeedback({});
    }

    await enrollUser();

    setEnrollCollectedData(undefined);
  };

  const onPromptForRevokeInfoHandler = () => {
    menuClose();
    setRevokeModal(true);
  };

  const revokeCancelModal = () => {
    setRevokeModal(false);
    setRevokeModalFeedback(false);
  };

  const onRevokeReasonChange = (e) => {
    setRevokeReason(e.target.value);
  };

  const revokeConfirmWithInfo = async () => {
    if (!revokeReason || revokeReason.trim() === '') {
      setRevokeModalFeedback(true);
      return;
    }
    setRevokeModal(false);

    // Revoke RP claims
    const result = await revokeEnrollmentSvc({mobileNumber, revokeReason});
    if (result?.status===201) {
      const msg = <span>IDgo Account Revoked</span>; 
      alert.show(msg, { title: 'Success', onClose: () => {} });
      setAccountStatus({status:0}); // trigger refresh
      return;
    }
    alert.show('System issue', {title: 'Error processing request'});

    setRevokeModalFeedback(false);
    setRevokeReason('');
  };

  const deleteConfirmWithInfo = async () => {
    // agent must type 'delete' to proceed
    if (deleteVerification.trim() !== 'delete') {
      setDeleteModalFeedback(true);
      return;
    }
    setDeleteModal(false);

    // delete the IDgo account
    const result = await deleteAccountSvc({coziId});
    if (result?.status===200) {
      const msg = <span>IDgo Account Deleted</span>; 
      alert.show(msg, { title: 'Success', onClose: () => {} });
      setAccountStatus({status:0}); // trigger refresh
      return;
    }
    alert.show('System issue', {title: 'Error processing request'});

    setDeleteModalFeedback(false);
    setDeleteVerification('');
  };

  const handleCallerIdNomatchChange = () => {
    setCallerIdNomatchChecked(!callerIdNomatchChecked);
  };

  const onCancelButtonClick = () => {
    setAuthContext(undefined);
    setDisabled(false);
    props.disableLookup(false);
  };

  const onAuthResult = (verificationRequestId) => {
    history.push({ pathname: `/auth-result/${verificationRequestId}`, hash: ctxValue('SUBDOMAIN') });
  };

  const renderAccountTools = () => {
    const isAutoEnroller          = decodedToken?.roles.includes('auto-enroller');
    const isRevoker               = decodedToken?.roles.includes('revoker');
    const isDeleter               = decodedToken?.roles.includes('account-deleter') && g_env!=='PROD';
    const validAccount            = (accountStatus?.status===200);
    const canRevoke               = (accountStatus.canRevoke===true);
    const authMenuItemDisabled    = (!validAccount || accountStatus?.canVerify===0);
    const enrollMenuItemDisabled  = (!isAutoEnroller);
    const revokeMenuItemDisabled  = (!validAccount || !isRevoker  || !canRevoke);
    const deleteMenuItemDisabled  = (!validAccount || !isDeleter);

    return <>
      <IconButton
        color="primary"
        className={styles.menuButton}
        style={{marginLeft:'6px',marginTop:'-50px'}}
        id='menu-tools-button'
        aria-controls={menuOpen ? 'menu-tools' : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}
        onClick={menuClick}
        disabled={disabled}
      >
        <ArrowDropDownCircleIcon />
      </IconButton>
      <Menu
        id='menu-tools'
        anchorEl={menuAnchor}
        open={menuOpen}
        onClose={menuClose}
        MenuListProps={{'aria-labelledby':'menu-tools-button'}}
      >
        <MenuItem dense={true} onClick={onEnrollHandler} disabled={enrollMenuItemDisabled} data-testid='menuitem-enroll'>Enroll</MenuItem>
        <MenuItem dense={true} onClick={onAuthenticationHandler} disabled={authMenuItemDisabled} data-testid='menuitem-authentication'>Authenticate</MenuItem>
        <Divider />
        <MenuItem dense={true} onClick={onPromptForRevokeInfoHandler} disabled={revokeMenuItemDisabled} data-testid='menuitem-revoke'>Revoke Enrollment</MenuItem>
        { isDeleter ?
          <MenuItem dense={true} onClick={onPromptForDeleteAccountHandler} disabled={deleteMenuItemDisabled} data-testid='menuitem-delete-account'>Delete Account</MenuItem> : ''
        }
        <Divider />
        <MenuItem dense={true} onClick={openProofsReivewWindow} data-testid='menuitem-open-proofs'>Open Proofs Window</MenuItem>
      </Menu>
    </>
  };

  const renderAccountStatus = () => {
    return <>
      <div style={{ height: '140px', backgroundColor: '#FBFBFB'}}>
        { renderAccountTools() }
        <div style={{display:'block', marginLeft:'auto', marginRight:'auto', width:'80%'}}>
          <small className='text-muted'>
            Account since: {moment.utc(accountStatus.createdDtm).local().format(dateFormat)}
            <ul>
              { accountStatus?.status && accountStatus.proofs.map((row) => renderProof(row)) }
            </ul>
          </small>
        </div>
      </div>
    </>
  };

  const renderProof = (row) => {
    if (row?.info && row.info.constructor===Array && row.info.length>0) {
      return <li key={row.name}>
        <button className={styles.popup}>
          <span className={styles.popupContent}>{ row.info.map((info) => renderProofPopup(info)) }</span> 
          {row.name}
          {renderProofText(row)}
        </button>
      </li>
    }
    // proof row without popup info
    return <li key={row.name}>
        <span className={styles.proof}>
          {row.name}
          {renderProofText(row)}
        </span>
      </li>
  };

  const renderProofText = (row) => {
    // map the middle-tier color to a CSS color
    const status_color = {'yellow':'orange','green':'green','red':'red'}[row.color] || 'black';
    const testId = `account-status-${row.name.toLowerCase().replace(/ /g,'-')}`;
    return <b style={{paddingLeft:'10px', color:status_color}} data-testid={testId}>{row.value}</b>;
  };

  const renderProofPopup = (info) => {
    const val = (info.type==='date') ? moment.utc(info.value).local().format(dateFormat) : info.value;
    return <div key={info.label}><b>{info.label}</b><span style={{marginLeft:'8px'}}>{val}</span></div>
  };

  const renderAccountNotAvailable = (status) => {
    return <>
      <div className='text-center'>
        { renderAccountTools() }
        <div style={{ height: '110px'}}>
          <small className='text-muted' style={{ marginLeft: '-50px' }} data-testid='account-status'>
            {status===404 && 'No IDgo Account for this mobile number'}
            {status===500 && 'System error retrieving account'}
            {status===0   && 'checking for account info...'}
          </small>
        </div>
      </div>
    </>
  };

  const renderEnrollCollectData = () => {
    return <>
      { accountStatus.enrollCollectData.map((item) => {
        return <FormGroup key={item.field}>
          <Input
            placeholder={item.label}
            style={{ width: '100%', fontSize: '11pt', marginTop: '10px' }}
            onChange={(e) => onEnrollCollectedDataChange(e, item.field)}
            invalid={item.field in enrollCollectDataModalFeedback}
          ></Input>
          <FormFeedback
            style={{marginBottom: '10px'}}
            >{enrollCollectDataModalFeedback[item.field]}
          </FormFeedback>
        </FormGroup>
        }) 
      }
    </>
  };

  const renderAccountToolsPanel = () => {
    return <>
      { (accountStatus?.canVerify===1 && relyingParty?.showCallerIdNomatchCheckbox==='true') ?
        <div>
          <label className={styles.callerIdNomatchCheckbox}>
            <input className={styles.callerIdNomatchInput} type='checkbox' checked={callerIdNomatchChecked} onChange={handleCallerIdNomatchChange} />
            <small  className={styles.callerIdNomatchLabel}>Caller ID does not match</small>
          </label>
        </div> : ''
      }
      <AccountToolsPanel
        canEnroll={accountStatus?.status===404 || accountStatus?.canVerify===0}
        canVerify={accountStatus?.canVerify===1}
        onEnroll={onEnrollHandler}
        onAuthentication={onAuthenticationHandler}
      />
    </>
  };

  return (
    <>
      <div style={{ width: '100%' }}>
        { (accountStatus && accountStatus?.status===200) ? renderAccountStatus() : renderAccountNotAvailable(accountStatus.status) }
        <div className='text-center'>

        <Modal isOpen={enrollCollectDataModal} backdrop={false}>
            <ModalHeader>Enroll</ModalHeader>
            <ModalBody>
              <p style={{ fontSize: '11pt' }}>
                Please provide the following account holder information
              </p>
              { (accountStatus?.enrollCollectData) ? renderEnrollCollectData() : '' }
            </ModalBody>
            <ModalFooter style={{ paddingTop: '0px', borderTopWidth: '0px' }}>
              <button className='btn btn-primary btn-xs' name='modal-button' onClick={enrollConfirmWithInfo} data-testid='button-approve-ok'>
                OK
              </button>
              <button className='btn btn-light btn-xs' onClick={enrollCancelModal} data-testid='button-approve-cancel'>
                Cancel
              </button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={revokeModal} backdrop={false}>
            <ModalHeader>Revoke enrollment</ModalHeader>
            <ModalBody>
              <p style={{ fontSize: '11pt' }}>
                Please enter reason for revoking this enrollment
              </p>
              <Input
                placeholder='Reason'
                style={{ width: '100%', fontSize: '11pt' }}
                onChange={onRevokeReasonChange}
                invalid={revokeModalFeedback}
                data-testid='input-reason'
              ></Input>
              <FormFeedback>Reason required!</FormFeedback>
            </ModalBody>
            <ModalFooter style={{ paddingTop: '0px', borderTopWidth: '0px' }}>
              <button className='btn btn-primary btn-xs' name='modal-button' onClick={revokeConfirmWithInfo} data-testid='button-revoke-ok'>
                OK
              </button>
              <button className='btn btn-light btn-xs' onClick={revokeCancelModal} data-testid='button-revoke-cancel'>
                Cancel
              </button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={deleteModal} backdrop={false}>
            <ModalHeader>Delete IDgo Account</ModalHeader>
            <ModalBody>
              <p style={{ fontSize: '11pt' }}>
                Please confirm deleting this IDgo account by typing: 'delete'
              </p>
              <Input
                placeholder="Type 'delete' to verify"
                style={{ width: '100%', fontSize: '11pt' }}
                onChange={onDeleteVerificationChange}
                invalid={deleteModalFeedback}
                data-testid='input-delete-verification'
              ></Input>
              <FormFeedback>Verification text incorrect!</FormFeedback>
            </ModalBody>
            <ModalFooter style={{ paddingTop: '0px', borderTopWidth: '0px' }}>
              <button className='btn btn-primary btn-xs' name='modal-button' onClick={deleteConfirmWithInfo} data-testid='button-delete-ok'>
                OK
              </button>
              <button className='btn btn-light btn-xs' onClick={deleteCancelModal} data-testid='button-delete-cancel'>
                Cancel
              </button>
            </ModalFooter>
          </Modal>

          { authContext
            ? 
              <PendingAuthentication
                mobileNumber={mobileNumber}
                authContext={authContext}
                cancelWaitingForAuth={onCancelButtonClick}
                handleAuthResult={onAuthResult}
              />
            :
              renderAccountToolsPanel()
          }

        </div>
      </div>
    </>
  );
};

export default AccountStatus;