import React from "react";
import { Link } from "react-router-dom";

// Utils
import { ctxValue } from '../../utils/config';

const ChangeEmailSuccess = (props) => {
  const { email } = props.location.state;

  return (
    <>
      <div
        className="text-center"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -45%)",
        }}
      >
        <p style={{ marginBottom: "10px" }}>
          <img
            src={`${process.env.PUBLIC_URL}/icons/icn_saved_blu.png`}
            alt="Success icon"
            style={{ width: "60px", height: "58px" }}
          ></img>
        </p>
        <p className="text-nowrap">Your changes have been saved!</p>
        <div className="form-group text-left" style={{ marginBottom: "25px" }}>
          <p>
            <small>
              <u>Please sign in with your new email address.</u>
            </small>
          </p>
          <label
            className="text-muted"
            style={{ margin: "0px" }}
            htmlFor="currentEmail"
          >
            <small>Your new email address</small>
          </label>
          <p>{email}</p>
        </div>
        <Link
          className="btn btn-primary btn-lg"
          style={{ marginBottom: "40px" }}
          to={'/#'+ctxValue('SUBDOMAIN')}
        >
          Back to Sign In
        </Link>
      </div>
    </>
  );
};

export default ChangeEmailSuccess;