export const setValidPasswordInput = (id) => {
  let passwordInput = document.getElementById(id);
  let formControl = passwordInput
    .getElementsByClassName("form-control")
    .item(0);
  let showHideBtn = passwordInput.getElementsByClassName("btn").item(0);
  let invalidFeedback = passwordInput
    .getElementsByClassName("invalid-feedback")
    .item(0);

  formControl.classList.remove("invalid");
  formControl.classList.remove("was-validated");

  invalidFeedback.style.display = "none";

  showHideBtn.classList.remove("invalid");
};

export const setInvalidPasswordInput = (id) => {
  let passwordInput = document.getElementById(id);

  let inputFormControl = passwordInput
    .getElementsByClassName("form-control")
    .item(0);

  let invalidFeedback = passwordInput
    .getElementsByClassName("invalid-feedback")
    .item(0);

  let showHideButton = passwordInput.getElementsByClassName("btn").item(0);

  inputFormControl.classList.add("invalid");

  invalidFeedback.style.display = "block";

  showHideButton.classList.add("invalid");
  showHideButton.classList.remove("was-validated");
};

export const setValidEmail = (id) => {
  document.getElementById(id).classList.remove("was-validated");
  document
    .getElementById(id)
    .getElementsByClassName("form-control")
    .item(0)
    .classList.remove("invalid");

  document
    .getElementById(id)
    .getElementsByClassName("invalid-feedback")
    .item(0).style.display = "none";
};

export const setInvalidEmail = (id) => {
  document
    .getElementById(id)
    .getElementsByClassName("form-control")
    .item(0)
    .classList.add("invalid");

  document
    .getElementById(id)
    .getElementsByClassName("invalid-feedback")
    .item(0).style.display = "block";
};
