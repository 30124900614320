import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as queryString from 'query-string';
import toast from 'react-hot-toast';
import { jwtDecode } from 'jwt-decode';
import PubNubReact from 'pubnub';

// Helpers
import { onClickFocus } from '../../utils/helpers';

//Context
import { useRelyingParty } from '../../context/RelyingPartyContext';

// Components
import AccountStatus from './AccountStatus';

// Utils
import { ctxValue } from '../../utils/config';
import { updateCallStatusByCallID as updateCallStatusByCallIDSvc, getRelyingParty } from '../../utils/services';
import { getAccessToken } from '../../utils/session';
import { formatMobileNumber } from '../../utils/helpers';

// Material components
import { IconButton } from '@mui/material';
// Material icons
import ClearIcon from '@mui/icons-material/Clear';

//Styles
import styles from './Lookup.module.css';

const pubnub = new PubNubReact({
  subscribe_key: ctxValue('PUBNUB_SUBSCRIBE_KEY'),
  ssl: true,
  uuid: 'call-center-app',
});

const Lookup = () => {
  const history = useHistory();
  const {relyingParty, setRelyingParty} = useRelyingParty();
  const [formattedMobileNumber, setFormattedMobileNumber] = useState('');
  const [lookupDisabled, setLookupDisabled] = useState(false);
  const [decodedToken, setDecodedToken] = useState();

  // useEffect dependent functions...

  const removeMobileNumberFormatting = (inputValue) => {
    // Remove non-numeric characters
    return inputValue.replace(/\D/g, '');
  };

  const validateAndFormatMobileNumber = (mobileNum) => {
    setFormattedMobileNumber(formatMobileNumber(mobileNum));
  };

  useEffect(() => {
    async function __getAccessToken() {
      const {token} = await getAccessToken();
      if (!token) return;
      const decoded = jwtDecode(token);
      setDecodedToken(decoded)
    };
    __getAccessToken();
  }, []);

  useState(() => {
    if ( ! history?.location?.search) return;
    const qs = queryString.parse(history.location.search);
    if ( ! qs?.mobileNumber || isNaN(qs.mobileNumber)) return;
    validateAndFormatMobileNumber(qs?.mobileNumber);
  }, [history.location.search]);

  useState(() => {
    async function __getRelyingPartyInfo() {
      const rpResult = await getRelyingParty();
      setRelyingParty(rpResult?.data);
    };
    __getRelyingPartyInfo();  

    if ( ! history?.location?.search) return;
    const qs = queryString.parse(history.location.search);
    if ( ! qs?.mobileNumber || isNaN(qs.mobileNumber)) return;

    // looks like IDgo Agent was launched with a query-string to pre-populate the mobile number

    const handleUnload = (_e) => {
      if (window?.opener) { // for poped up windows
        if (relyingParty?.agentPopupOrigin) {
          window.opener.postMessage({result: 'browser-closed', source:'IDgoAgent'}, relyingParty.agentPopupOrigin);
          return;
        }
        console.warn(`Lookup: relyingParty.agentPopupOrigin not set - unable to postMessage to opener`);
      }
      if (window?.parent) { // for iframed up windows
        console.warn(`Lookup: posting data: {result:'browser-closed'}`);
        window.parent.postMessage({result: 'browser-closed', source:'IDgoAgent'}, '*');
        return;
      }
    };
    window.addEventListener('unload', handleUnload);
    return () => {
      window.removeEventListener('unload', handleUnload);
    }
  }, [relyingParty]);

  useEffect(() => {
    const subdomain = ctxValue('SUBDOMAIN');
    const channel = `agentnotify.${subdomain}.${decodedToken?.agentId}`;
    async function __listenForPubnubMessages () {
      const listener = {
        message: (msg) => {
          // if running within the desktop widget, call the 'show' API
          if (window?.electronAPI?.show) {
            console.debug(`Lookup.useEffect([agentId]}) calling: window.electronAPI.show()`);
            window.electronAPI.show(); // show the window if minimized
          } else {
            console.debug(`Lookup.useEffect([agentId]}) window.electronAPI.show not found`);
          }
          if (msg.message?.authStatus === 'pass') {
            if (msg.message?.callId) {
              updateCallStatusByCallIDSvc({callId: msg.message.callId, subdomain, operation: 'takecall'});
            }
            const callWithClaimData = { callId: msg.message?.callId, callerData: { agentClaimData: msg.message?.agentClaimData } };
            history.push({ pathname: '/auth-result', hash: subdomain, state: { call: callWithClaimData, isOnDemand: false } });
            toast.success('Authenticated call', {position:'top-left', duration:4000});
            return;
          }
          if (msg.message?.authStatus === 'rejected') {
            validateAndFormatMobileNumber(msg.message.mobileNumber);
            toast.error('Authentication rejected by user', {position:'top-left', duration:8000});
            return;
          }
          if ( 'authStatus' in msg.message && (!msg.message.authStatus) ) {
            validateAndFormatMobileNumber(msg.message.mobileNumber);
            toast.error('Authentication timed out', {position:'top-left', duration:8000});
            return;
          }
          validateAndFormatMobileNumber(msg.message.mobileNumber);
          toast.success('Incomming call', {position:'top-left', duration:8000});
        },
      };
      pubnub.addListener(listener);
      console.debug(`Lookup.useEffect() subscribing to channel: ${channel}`);
      pubnub.subscribe({ channels: [channel] });
      return;
    };

    if (!decodedToken?.agentId) return;
    __listenForPubnubMessages();

    return () => {
      if (!decodedToken?.agentId) return;
      pubnub.unsubscribe({ channels: [channel] });
      pubnub.removeAllListeners(); // required even though it shows as "may not exist" (or you will get duplicates)
    };
    // eslint-disable-next-line
  }, [decodedToken?.agentId]);

  const clearLookupFormHandler = () => {
    setFormattedMobileNumber('');
    document.getElementById(`search-input`).focus();
  };

  return (
    <>
      <div className={styles.inputGroup}>
        <input
          id='search-input'
          type='tel'
          className={styles.accountInput}
          placeholder='Mobile number'
          name='search'
          value={formattedMobileNumber}
          onChange={(e) => validateAndFormatMobileNumber(e.target.value)}
          onClick={(e) => onClickFocus(e)}
          autoFocus
          autoComplete='off'
          maxLength='14'
          data-testid='search-input'
          disabled={lookupDisabled}
        />
        <label htmlFor='search-input'>Mobile number</label>
        <IconButton
          className={styles.clearIcon}
          style={{marginTop:'-4px'}}
          id='basic-button'
          onClick={clearLookupFormHandler}
          disabled={lookupDisabled}
        >
          <ClearIcon />
        </IconButton>
      </div>

      { formattedMobileNumber.length > 13 && (
        <AccountStatus
          phone={removeMobileNumberFormatting(formattedMobileNumber)} 
          disableLookup={setLookupDisabled}
        />
      )}
    </>
  );
};

export default Lookup;