// utils/helpers.js

const joi = require('joi');

function _clone(a) { return JSON.parse(JSON.stringify(a)); }

export const isValidEmail = (email) => {
  let emailRegex = /\S+@\S+\.\S+/;
  return emailRegex.test(email);
};

export const isValidPassword = (password) => {
  return password.length > 0;
};

export const isEmpty = (inputValue) => {
  return inputValue.length > 0;
};

export const onClickFocus = (e) => {
  let input = e.target;
  let value = input.value;
  if (value === '' || value === undefined || value === null){
    input.focus();
  }
};

export const formatMobileNumber = (mobileNumber) => {
  // Remove non-numeric characters
  const cleaned = mobileNumber.replace(/\D/g, '');
  // Check if the number is a valid 10-digit US number
  if (cleaned.length === 10) {
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
  }
  return mobileNumber; // Return as-is if not 10 digits
};

// joi custom helper
const _isNumeric = (value, helpers) => {
  if (!/^\d*$/.test(value)) {
    return helpers.message('value must be numeric')
  }
  return value;
};

export const validateParamsWithRules = (params, enrollDataRules) => {
  let validateResult;
  // make a copy so we don't change the original rules object
  let rules = _clone(enrollDataRules);
  // Build JOI validation rules based on declarative data
  const vRules = {};
  // Build the JOI validation object for each parameter
  for (var param of Object.keys(rules)) {
    let jRules;
    while (rules[param].length) {
      const r = rules[param].shift();
      switch (r) {
        case 'string':
          jRules = (jRules) ? jRules.string() : joi.string();
          break;
        case 'alphanum':
          jRules = (jRules) ? jRules.alphanum() : joi.alphanum();
          break;
        case 'numeric':
          jRules = (jRules) ? jRules.custom(_isNumeric) : joi.custom(_isNumeric);
          break;
        case 'min':
          const pMin = rules[param].shift();
          jRules = (jRules) ? jRules.min(pMin) : joi.min(pMin);
          break;
        case 'max':
          const pMax = rules[param].shift();
          jRules = (jRules) ? jRules.max(pMax) : joi.max(pMax);
          break;
        case 'length':
          const pLength = rules[param].shift();
          jRules = (jRules) ? jRules.length(pLength) : joi.length(pLength);
          break;
        case 'required':
          jRules = (jRules) ? jRules.required() : joi.required();
          break;
        default:
          validateResult =
            { error:
              { details:
                [ { context: {label: param, key: param}
                  , message: `Validation rule: "${r}" not supported`
                  , path   : [param]
                  }
                ]
              }
            }
      }
    }
    // override the default messages to removed the \"fieldName\"" prefixes
    jRules = jRules.messages(
      { 'string.pattern.base' : 'must be numeric'
      , 'string.min'          : 'must have a minimum length of {#limit}'
      , 'string.max'          : 'must have a maximum length of {#limit}'
      , 'string.length'       : 'length must be {#limit}'
      , 'string.alphanum'     : 'must contain alphanumeric charactors only'
      , 'any.required'        : 'required input'
      });
    vRules[param] = jRules;
  }

  // return without validation if there was an error building the JOI validator
  if (validateResult) return validateResult;

  const requiredParams = joi.object(vRules);
  return requiredParams.validate(params, { abortEarly: false });
};