import React, { useEffect } from 'react';
import PubNubReact from 'pubnub';
import { useAlert } from 'react-alert';

// Utils
import { ctxValue } from '../../utils/config';

//Styles
import styles from './PendingAuthentication.module.css';

//Images
import processing from './processing.gif';

const pubnub = new PubNubReact({
  subscribe_key: ctxValue('PUBNUB_SUBSCRIBE_KEY'),
  ssl: true,
  uuid: 'call-center-app',
});

let lastPubnubResult = '';

const PendingAuthentication = (props) => {
  const alert = useAlert();

  useEffect(() => {
    const channel = `authnotify.${props.authContext}`;
    const listener = {
      status: (st) => {
        if (st.category === 'PNUnknownCategory') {
          const newState = { new: 'error' };
          pubnub.setState( { state: newState }, (_status) => { 
            console.debug(`PendingAuthentication.useEffect([props.authContext]) pubnub.setState() st: ${JSON.stringify(st.errorData.message,null,3)}`);
          });
        }
      },
      message: (msg) => {
        // check for and remove duplicates
        if (lastPubnubResult===msg.message.authcontext) {
          console.info(`PendingAuthentication.useEffect([props.authContext]) dropping duplicate authContext event: ${lastPubnubResult}`);
          return;
        }

        lastPubnubResult = msg.message.authcontext;
        pubnub.unsubscribe({ channels: [`authnotify.${msg.message.authcontext}`] });

        if ( ! msg.message?.details?.verificationRequestId ) {
          alert.show(<div>Authenticate error no verificationRequestId provided!</div>, { type: 'error', title: 'System Error', onClose: () => { onCancelButtonClick(); } });
          return;
        }

        props.handleAuthResult(msg.message.details.verificationRequestId);
      }
    };

    pubnub.addListener(listener);
    pubnub.subscribe({ channels: [channel] });
    console.debug(`PendingAuthentication() listening for authentications on channel: ${channel}`);

    return () => {
      pubnub.unsubscribe({ channels: [channel] });
      pubnub.removeAllListeners(); // required even though it shows as "may not exist" (or you will get duplicates)
    };
    // eslint-disable-next-line
  }, [props.authContext]);

  const onCancelButtonClick = () => {
    pubnub.unsubscribe({ channels: [`authnotify.${props.authContext}`] });
    props.cancelWaitingForAuth();
  };

  return (
    <>
      <div className='text-center'>
        <div className={styles.authHeader}>
          Authentication in progress
          <img alt={'processing...'} src={processing} className={styles.processingImage}></img>
        </div>
        <input type='button' className='btn btn-outline-danger btn-lg shadow-none' value='Cancel' onClick={ onCancelButtonClick } />
      </div>
    </>
  );
};

export default PendingAuthentication;