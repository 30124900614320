import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as queryString from 'query-string';
import moment from 'moment';

//Utils
import 
{ getProofVerificationActivity as getProofVerificationActivitySvc
} from '../../utils/services';
import { setWindowSize } from '../../utils/session';

//Styles
import styles from './ProofsReview.module.css';

function ProofsReview() {
  const history = useHistory();
  const [mobileNumber, setMobileNumber] = useState();
  const [proofActivity, setProofActivity] = useState();

  useEffect(() => {
    if (!mobileNumber && history.location.search){
      const mobile_no_qs = getQueryStringParam(history.location.search, 'm');
      if (mobile_no_qs) {
        setMobileNumber(mobile_no_qs);
      }
    }
    async function getProofVerificationActivity () {
      var startOfToday = moment().startOf('day').toISOString();
      const result = await getProofVerificationActivitySvc({mobileNumber, startDatetime: startOfToday});
      if (result?.status!==200) return;
      setProofActivity(result.data);
    };
    if (!mobileNumber) return;
    getProofVerificationActivity();  
  }, [mobileNumber, history.location.search, setProofActivity]);

  useEffect(() => {
    const onResize = () => {
      setWindowSize('proofsReviewWinSize', {innerWidth: window.innerWidth, innerHeight: window.innerHeight, screenX: window.screenX, screenY:window.screenY});
    }
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, []);
  
  const getQueryStringParam = (qs, param) => {
    const params = queryString.parse(qs);
    return params[param] || undefined;
  }

  const fetchActivity = async (startDatetime) => {
    const result = await getProofVerificationActivitySvc({mobileNumber, startDatetime});
    if (result?.status!==200) return;
    setProofActivity(result.data);
};

  const onActivityToday = async () => {
    fetchActivity(moment().startOf('day').toISOString());
  };

  const onActivityLast30 = async () => {
    fetchActivity(moment().subtract(30, 'd').toISOString());
  };

  return (
    <div className={styles.ProofsWindow}>
      <div className={styles.containerWrapper}>
        <div className={styles.buttonBar}>
          <span className={styles.buttonWrapper}><button className='btn btn-primary btn-lg' onClick={onActivityToday}>Today</button></span>
          <span className={styles.buttonWrapper}><button className='btn btn-primary btn-lg' onClick={onActivityLast30}>Last 30 Days</button></span>
          <span className={styles.buttonWrapper}><button className='btn btn-primary btn-lg' onClick={() => window.close()}>Close</button></span>
        </div>
        <div className={styles.containerScroller}>
          <div className={styles.activityGridContainer}>
            { proofActivity && proofActivity.map((row, index) => {
              const cur_dt  = moment.utc(row.createdDtm).local().format('MMMM Do, YYYY');
              const last_dt = (index===0)
                  ? ''
                  : moment.utc(proofActivity[index-1].createdDtm).local().format('MMMM Do, YYYY');
              const header = (cur_dt!==last_dt)
                  ? <div className={styles.header}>{cur_dt}</div>
                  : '' ;
              const outcome = (row.success===1)
                  ? <div className={styles.success}>success</div>
                  : <div className={styles.failed}>failed</div>;

              return <>
                      {header}
                      <div className={styles.createdDtm}>{moment.utc(row.createdDtm).local().format('h:mm a')}</div>
                      <div className={styles.eventName}>{row.eventName}
                        <span className={styles.popupContent}>{row.result}</span>
                      </div>
                      {outcome}
                      <div></div>
                    </>
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProofsReview;
