import React from "react";
import { Link } from "react-router-dom";

// Utils
import { ctxValue } from '../../utils/config';

const ChangePasswordSuccess = () => {
  return (
    <>
      <div
        className="text-center"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -45%)",
        }}
      >
        <p style={{ marginBottom: "10px" }}>
          <img
            src={`${process.env.PUBLIC_URL}/icons/icn_saved_blu.png`}
            alt="Success icon"
            style={{ width: "60px", height: "58px" }}
          ></img>
        </p>
        <p style={{ marginBottom: "40px" }}>Your changes have been saved!</p>
        <Link
          className="btn btn-primary btn-lg"
          style={{ marginBottom: "40px" }}
          to={'/#'+ctxValue('SUBDOMAIN')}
        >
          Back to Sign In
        </Link>
      </div>
    </>
  );
};

export default ChangePasswordSuccess;