import React from "react";

const EmailAddressInput = ({ id, name, value, onChange, onClick }) => {
  return (
    <div className="form-label-group">
      <input
        id={id}
        name={name}
        value={value}
        type="email"
        className="form-control"
        aria-label="Email address input"
        placeholder="Email address"
        onChange={(e) => onChange(e)}
        onClick={(e) => onClick(e)}
        autoComplete="off"
        maxLength="128"
        data-testid="email"
      />
      <label htmlFor="email">Email address</label>
      <div className="invalid-feedback">Please enter a valid email address</div>
    </div>
  );
};

export default EmailAddressInput;
