import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Context
import { useRelyingParty } from '../../context/RelyingPartyContext';

// Components
import InputValidation from '../common/InputValidation';
import Alert from '../layout/Alert';

// Utils
import { ctxValue } from '../../utils/config';
import { forgotPassword } from '../../utils/services';
import { isValidEmail } from '../../utils/helpers';
import { setInvalidEmail, setValidEmail } from '../../utils/toggleValidationFeedback';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isSent, setIsSent] = useState(false);
  const {relyingParty} = useRelyingParty();
  const [alerts, setAlerts] = useState([]);

  //console.debug(`ForgotPassword() relyingParty: ${JSON.stringify(relyingParty,null,3)}`);

  const onChange = (e) => {
    setEmail(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const validEmail = isValidEmail(email);

    if (validEmail) setValidEmail(`email-input-group`)
    else setInvalidEmail(`email-input-group`)

    if (!validEmail) return;

    const result = await forgotPassword(email);

    if (result?.status!==200) {
      setAlerts([...alerts, {heading: 'Hang On!', msg:result.message, type:'danger'}]);
      setTimeout(() => setAlerts([]), 8000);
      return;
    }
   
    setIsSent(true);
  };

  const emailForm = (
    <>
      <InputValidation formName='sendEmailForm' />
      <div className='text-center mx-auto' style={{ marginTop: '10px' }}>
        <Alert alerts={alerts}/>
        <div style={{ marginBottom: '20px' }}>
          <h6>IDgo Agent - {relyingParty?.name}</h6>
          <p style={{ fontSize: '16pt' }}>Password assistance</p>
        </div>
        <p
          className='text-left'
          htmlFor='email'
          style={{ width: '24ch', fontSize: '14pt', marginBottom: '25px' }}
        >
          Enter the email address associated with your account
        </p>
        <form
          name='sendEmailForm'
          onSubmit={(e) => onSubmit(e)}
          method='post'
          noValidate
        >
          <div
            id='email-input-group'
            className='form-group form-label-group'
            style={{ marginBottom: '40px' }}
          >
            <input
              id='email-input'
              type='email'
              className='form-control'
              aria-label='New email address'
              name='email'
              value={email}
              onChange={(e) => onChange(e)}
              required
              autoFocus
              autoComplete='off'
              placeholder='Email address'
            />
            <label htmlFor='email-input' className='text-left'>
              Email address
            </label>
            <div className='invalid-feedback'>
              Please enter a valid email address
            </div>
          </div>
          <div style={{ marginBottom: '40px' }}>
            <Link
              to={'/#' + ctxValue('SUBDOMAIN')}
              className='btn btn-outline-primary btn-sm shadow-none float-left'
            >
              Back
            </Link>
            <button
              type='submit'
              className='btn btn-primary btn-sm shadow-none float-right'
            >
              {' '}
              Continue
            </button>
          </div>
        </form>
      </div>
    </>
  );

  const emailSent = (
    <>
      <div className='text-center mx-auto' style={{ marginTop: '25px' }}>
        <div style={{ marginBottom: '20px' }}>
          <h6>IDgo Agent - {relyingParty?.name}</h6>
          <p style={{ fontSize: '16pt' }}>Password assistance</p>
        </div>
        <label
          className='text-center'
          style={{ marginBottom: '25px' }}
          htmlFor='email'
        >
          A reset email has been sent.
          <br /> <strong>Check your email inbox!</strong>
        </label>
        <Link to={'/#' + ctxValue('SUBDOMAIN')} className='btn btn-primary btn-lg shadow-none'>
          Back to Login
        </Link>
      </div>
    </>
  );

  return <div className='container'>{isSent ? emailSent : emailForm}</div>;
};

export default ForgotPassword;