import React from "react";
import { createRoot } from 'react-dom/client';

// Styles
import "./index.css";

// Components
import App from "./App";

import { positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-mui';

import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './utils/msalConfig';

let g_msalInstance;

if (msalConfig) {
  try {
    g_msalInstance = new PublicClientApplication(msalConfig);
    console.debug(`index.js - msalConfig: ${JSON.stringify(msalConfig,null,4)}`);
  } catch (err) {
    console.debug(`index.js - exception creating msalInstance: ${err}`);
  }
}

const alert_opts = { position: positions.MIDDLE };
const container = document.getElementById('app');
const root = createRoot(container);

let instanceInitialized = false;

if (g_msalInstance) {
  await g_msalInstance.initialize()
    .then(() => {
      const activeAccount = g_msalInstance.getActiveAccount();
      const allAccounts = g_msalInstance.getAllAccounts();
      // Default to using the first account if no account is active on page load
      if (!activeAccount && allAccounts.length > 0) {
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        g_msalInstance.setActiveAccount(allAccounts[0]);
      }
      // Optional - This will update account state if a user signs in from another tab or window
      g_msalInstance.enableAccountStorageEvents();
      g_msalInstance.addEventCallback((event) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
          const account = event.payload.account;
          g_msalInstance.setActiveAccount(account);
        }
      });
      root.render(
        <AlertProvider template={AlertTemplate} {...alert_opts}>
          <App msalInstance={g_msalInstance}/>
        </AlertProvider>
      );
      instanceInitialized = true;
    })
    .catch((err) => {
      g_msalInstance = undefined;
      console.error(`index.js - msalInstance.initialize() failed: ${err}`)
    });
}

if ( ! instanceInitialized ) {
  root.render(
    <AlertProvider template={AlertTemplate} {...alert_opts}>
      <App />
    </AlertProvider>);
}

export const msalInstance = g_msalInstance;