import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

// Components
import PasswordInput from '../common/PasswordInput';
import InputValidation from '../common/InputValidation';
import Alert from '../layout/Alert';

// Utils
import { ctxValue } from '../../utils/config';
import { changePassword, logout } from '../../utils/services';
import { onClickFocus, isValidPassword } from '../../utils/helpers';
import { setValidPasswordInput, setInvalidPasswordInput } from '../../utils/toggleValidationFeedback';

const ChangePassword = () => {
  const history = useHistory();
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [formData, setFormData] = useState({
    currentPassword: ``,
    newPassword: ``,
    confirmPassword: ``,
  });
  const { currentPassword, newPassword, confirmPassword } = formData;
  const [alerts, setAlerts] = useState([]);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const validCurrentPassword = isValidPassword(currentPassword);
    const validNewPassword     = isValidPassword(newPassword);
    const validConfirmPassword = isValidPassword(confirmPassword);

    if (validCurrentPassword) setValidPasswordInput(`current-password-input-group`);
    else setInvalidPasswordInput(`current-password-input-group`);

    if (validNewPassword) setValidPasswordInput(`new-password-input-group`);
    else setInvalidPasswordInput(`new-password-input-group`);

    if (validConfirmPassword) setValidPasswordInput(`confirm-password-input-group`);
    else setInvalidPasswordInput(`confirm-password-input-group`);

    if (!validCurrentPassword) return;
    if (!validNewPassword) return;
    if (!validConfirmPassword) return;

    if (newPassword !== confirmPassword) {
      setPasswordsMatch(false);
      setValidPasswordInput(`new-password-input-group`);
      setInvalidPasswordInput(`confirm-password-input-group`);
      return;
    }

    // Second check if the new password and the confirm new password inputs match
    // Display a validation message is the values do not much otherwise send a request
    if (newPassword !== confirmPassword) {
      setPasswordsMatch(false);
      setInvalidPasswordInput(`confirm-password-input-group`);
      return;
    }

    setPasswordsMatch(true);

    const result = await changePassword(currentPassword, newPassword);

    if (result?.status!==200) {
      setAlerts([...alerts, {heading: 'Hang On!', msg:result.message, type:'danger'}]);
      setTimeout(() => setAlerts([]), 6000);
      return;
    }

    history.push({ pathname: '/change-password-success', hash: ctxValue('SUBDOMAIN') });
    logout();
  };

  return (
    <>
      <InputValidation formName='changePasswordForm' />
      <div className='row'>
        <div className='text-center mx-auto'>
          <Alert alerts={alerts}/>
          <p
            className='text-nowrap'
            style={{ fontSize: '16pt', marginBottom: '10px' }}
          >
            Change Your Password
          </p>
          <form
            name='changePasswordForm'
            method='post'
            onSubmit={(e) => onSubmit(e)}
            noValidate
          >
            <div
              id='current-password-input-group'
              className='form-group changePasswordForm-validate-me'
              style={{ marginBottom: '30px' }}
            >
              <PasswordInput
                id='currentPassword'
                value={currentPassword}
                name='currentPassword'
                placeholder='Enter current password'
                onChange={onChange}
                onClick={(e) => onClickFocus(e)}
                invalidMessage={`Current password is required`}
              />
            </div>
            <div
              id='new-password-input-group'
              className='form-group changePasswordForm-validate-me'
              style={{ marginBottom: '30px' }}
            >
              <PasswordInput
                id='newPasswordInput'
                value={newPassword}
                name='newPassword'
                placeholder='Enter new password'
                onChange={onChange}
                onClick={(e) => onClickFocus(e)}
                invalidMessage={`New password is required`}
              />
            </div>
            <div
              id='confirm-password-input-group'
              className='form-group  changePasswordForm-validate-me'
              style={{ marginBottom: '25px' }}
            >
              <PasswordInput
                id='confirmPasswordInput'
                value={confirmPassword}
                name='confirmPassword'
                placeholder='Confirm new password'
                onChange={onChange}
                onClick={(e) => onClickFocus(e)}
                invalidMessage={
                  passwordsMatch
                    ? `Confirm password is required`
                    : `Confirm password must match new password`
                }
              />
            </div>
            <div style={{ marginBottom: '40px' }}>
              <Link
                className='btn btn-outline-primary btn-sm float-left'
                to={'/lookup#'+ctxValue('SUBDOMAIN')}
              >
                Cancel
              </Link>
              <input
                type='submit'
                value='Save'
                className='btn btn-primary btn-sm float-right'
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;