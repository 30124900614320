import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';

import { ctxValue } from '../../utils/config';
import { haveAccessToken } from '../../utils/session';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();

  const overrideRoute = () => {
    if ( ! haveAccessToken() ) {
      console.info(`PrivateRoute: haveAccessToken() was false`)
      return true;
    }
    return false;
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        overrideRoute() ? (
          <Redirect to={{pathname:'/', hash: ctxValue('SUBDOMAIN'), state:{location}}}/>
        ) : (
          <Component {...props} />
        )
      }
    />
  )
};

export default PrivateRoute;