import React from 'react';
import { Link } from 'react-router-dom';
import Emoji from '../common/Emoji';

import { ctxValue } from '../../utils/config';

//Styles
import styles from './ResetPasswordSuccess.module.css';

const ResetPasswordSuccess = () => {
  return (
    <>
      <div className='row justify-content-center align-items-center'>
        <div className={`card mx-auto ${styles.frame2}`}>
          <div className={`text-center mx-auto ${styles.frame3}`}>
            <div className='card-body'>
              <div className={styles.frame5}>
                <p className={`lead ${styles.frame6}`}>
                  Your password has successfully been reset! {` `}
                  <Emoji symbol='🥳' label='partying face' />
                </p>
              </div>
              <div style={{ marginTop: "10pt" }}>
                <Link to={`/#${ctxValue('SUBDOMAIN')}`} className="btn btn-primary btn-lg shadow-none">
                  Login to IDgo Agent
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordSuccess;