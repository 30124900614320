import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

//Context
import { useRelyingParty } from '../../context/RelyingPartyContext';

// Components
import PasswordInput from '../common/PasswordInput';
import EmailAddressInput from '../common/EmailAddressInput';
import InputValidation from '../common/InputValidation';
import Alert from '../layout/Alert';

// Utils
import { isValidEmail, isValidPassword, onClickFocus } from '../../utils/helpers';
import {
  setValidPasswordInput,
  setInvalidPasswordInput,
  setValidEmail,
  setInvalidEmail,
} from '../../utils/toggleValidationFeedback';
import { setAccessToken } from '../../utils/session';
import { ctxValue, gSubdomain } from '../../utils/config';
import { getRelyingParty, login } from '../../utils/services';
import { jwtDecode } from 'jwt-decode';

// Styles
import styles from './Login.module.css';

const Login = (props) => {
  const history = useHistory();
  const {instance: msal} = useMsal();
  const {relyingParty, setRelyingParty} = useRelyingParty();
  const [formData, setFormData] = useState({ email: '', password: '' });
  const {email, password} = formData;
  const [alerts, setAlerts] = useState([]);

  //console.debug(`Login() relyingParty: ${JSON.stringify(relyingParty,null,3)}`);

  useEffect(() => {
    async function __getRelyingPartyInfo() {
      const rpResult = await getRelyingParty();
      setRelyingParty(rpResult?.data);
      const rpName = rpResult?.data?.name;
      const env = ctxValue('ENV');
      const whitelistSite = window.location.href.startsWith('https://agent2') ? ' * ' : '';
      const title = (env==='PROD') 
                  ? `IDgo Agent - ${rpName}${whitelistSite}` 
                  : `IDgo Agent - ${rpName}${whitelistSite} ${env}`
                  ;
      document.title = title;
    };
    if (relyingParty?.name) return;
    __getRelyingPartyInfo();
    // eslint-disable-next-line
  }, []);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  /**
   * IDgo's legacy access tokens are more ID tokens than access tokens.
   * In order to support the legacy token approach, we need to treat the AAD idTokens as cozera access tokens.
   * So the 'idToken' is being cached as an access token, then used when calling IDgo micro-services.
   */
  const msalLogin = async () => {
    try {
      const result = await msal.loginPopup();
      const {idToken} = result;
      console.debug(`msalLogin() idToken:${idToken}`);
      const decodedToken = jwtDecode(idToken);
      if (!('roles' in decodedToken)) {
        console.warn(`msalLogin() returned idToken has no roles defined`);
        const msg = 'No "roles" defined for this account. Sign in aborted.';
        setAlerts([...alerts, {heading: 'Hang On!', msg, type:'danger'}]);
        setTimeout(() => setAlerts([]), 10000);
        return;
      }
      // logic to block agents signing if they don't have non-whitelist role
      if (window.location.href.startsWith('https://agent.')) {
        const { roles } = decodedToken;
        if (!(roles.includes('non-whitelist-ok'))) {
          console.warn('msalLogin() Agent does not have non-whitelist role');
          const msg = 'Your account only allows access from your enterprise locations.';
          setAlerts([...alerts, {heading: 'Hang On!', msg, type:'danger'}]);
          setTimeout(() => setAlerts([]), 10000);
          return;
        }
      }
      // no need to store the AAD token, masl takes care of this for us.
      setAccessToken('aad', undefined);
      gotoLandingPathname();
    } catch (err) {
      console.warn(`Login.msalLogin() login.Popup exception: ${err}`);
      const emsg = (err?.errorMessage) ? err?.errorMessage.split(/\r?\n/)[0] : err;
      setAlerts([...alerts, {heading: 'Hang On!', msg:emsg, type:'danger'}]);
      setTimeout(() => setAlerts([]), 10000);
    }
  };  

  const onLocalAccountLogin = async (e) => {
    e.preventDefault();

    const validEmail = isValidEmail(email);
    const validPassword = isValidPassword(password);

    if (validEmail) setValidEmail(`email-input-group`);
    else setInvalidEmail(`email-input-group`);

    if (validPassword) setValidPasswordInput(`password-input-group`);
    else setInvalidPasswordInput(`password-input-group`); 

    if (!validEmail) return;
    if (!validPassword) return;

    const result = await login(email, password);
    if (result?.status!==200) {
      setAlerts([...alerts, {heading: 'Hang On!', msg:result.message, type:'danger'}]);
      setTimeout(() => setAlerts([]), 8000);
      return;
    }

    gotoLandingPathname();
  };

  const gotoLandingPathname = () => {
    // This state is passed down from the PrivateRoute redirect logic
    // After signing-in naviate to the original destination
    if (props?.location?.state?.location?.pathname) {
      const pathname = props?.location?.state?.location?.pathname;
      const search = props?.location?.state?.location?.search;
      history.push({ pathname, search, hash: gSubdomain })
      return;
    }

    history.push({ pathname: '/lookup', hash: gSubdomain })
  };

  return (
    <>
      <InputValidation formName='loginForm' />
      <div className='row'>
        <div className='text-center mx-auto' style={{ marginTop: '10px' }}>
          <div style={{ marginBottom: '20px' }}>
            <Alert alerts={alerts}/>
            <h6 data-testid='title'>IDgo Agent - {relyingParty?.name}</h6>
            <p style={{ fontSize: '16pt' }}>Sign in to your account</p>
          </div>
          <form
            name='loginForm'
            onSubmit={(e) => onLocalAccountLogin(e)}
            method='post' noValidate
          >
            <div id='email-input-group' style={{ marginBottom: '20px' }}>
              <EmailAddressInput id='email' name='email' value={email} onChange={onChange} onClick={(e) => onClickFocus(e)} />
            </div>
            <div id='password-input-group' style={{ marginBottom: '20px' }}>
              <PasswordInput id='password-input' name='password' value={password} onChange={onChange} onClick={(e) => onClickFocus(e)} placeholder='Password' invalidMessage={`Password field is required`} />
            </div>
            <button className='btn btn-primary btn-lg shadow-none' style={{ marginBottom: '10px' }} type='submit' data-testid='sign-in' >
              Sign In
            </button>
            <div>
              <Link to={`/forgot-password#${gSubdomain}`}>
                <small className='btn-link'>Forgot your password?</small>
              </Link>
            </div>
            { relyingParty?.hasAADTenantId ?
              <div className={styles.enterpriseLoginLink} onClick={() => msalLogin()}>Sign-in with your Enterprise account</div>
              : ''
            }
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;